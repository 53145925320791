import React from "react";
var createReactClass = require('create-react-class');

const Top = createReactClass({
 
  render: function() {
    return (
      <div id="top">
        <img src="/images/IngeniumInnovationLogo.svg" alt="logo Ingenium Innovation"/>
        <p className="lang"><a href="/">English</a></p>
        <div className="video-wrapper">
            <iframe title="L'innovation, ça se fête" src="https://www.youtube.com/embed/jxQKS1XOQ0s?rel=0&mute=1&autoplay=1&showinfo=0" frameBorder="0" volume="0" allowFullScreen></iframe>
        </div>
        <div className="topText">
          <h1>&Agrave; propos de R&eacute;cits d'innovation</h1>
          <p>Le recueil de r&eacute;cits d’innovation a &eacute;t&eacute; aliment&eacute; par le public en 2017, dans le cadre de l’initiative Innovation150, une initiative menée par cinq importants organismes visant &agrave; c&eacute;l&eacute;brer l’innovation canadienne.</p>
          <p>Tous les textes du recueil ont migr&eacute; vers le <a href="https://ingeniumcanada.org/fr/le-reseau" target="_blank" rel="noopener noreferrer">R&eacute;seau Ingenium</a>.Vous pouvez voir les articles sur le tableau <a href="https://ingeniumcanada.org/fr/le-reseau/tableaux/recits-innovation" target="_blank" rel="noopener noreferrer">R&eacute;cits d'innovation</a>.</p>
          <p>Le R&eacute;seau Ingenium est un portail num&eacute;rique qui met en vedette du contenu en lien avec les sciences, la technologie et l’innovation. Le R&eacute;seau permet aux passionn&eacute;s de science, aux enseignants, aux parents et &agrave; tous les curieux au pays de d&eacute;couvrir et de diffuser des textes, articles, nouvelles, blogues, balados et vid&eacute;os sur l’innovation.</p>
        </div>
      </div>
    )
  }
});

export default Top;