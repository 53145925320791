import React from "react";
import axios from "axios";

var createReactClass = require('create-react-class');

const InnovationRandomArticles = createReactClass({

  getInitialState: function() {
    return {
      jobs: []
    }
  },

  componentDidMount: function() {
    var th = this;
    this.serverRequest =
    axios.get("https://ingeniumcanada.org/splash.json")
        .then(function(result) {
          th.setState({
            jobs: result.data
          });
        })
  },

  componentWillUnmount: function() {
    this.serverRequest.abort();
  },

  render: function() {
    return (
      <div id="random">
        <h1>Innovation stories</h1>
        <div id="articles">
          {this.state.jobs.map(function(job) {
            const imgurl='https://ingeniumcanada.org'+job.field_main_image;
            const pageurl="https://ingeniumcanada.org"+job.view_node;

            var i = new Image();
            i.onload = function(){
              var element = document.getElementById(job.nid);
              if (i.naturalWidth < i.naturalHeight){
                element.classList.add("articleImageVertical");
              }else{
                element.classList.add("articleImage");
              }
            };
            i.src = imgurl;

            let titletext=job.title.length > 64 ? job.body.substring(0, 65) + "..." : job.title;
            titletext=titletext.replace(/&#039;|&quot;/g,'');
            const bodytext=job.body.length > 140 ? job.body.substring(0, 140) + "..." : job.body;
            let userlink=job.uid.split(' ').join('-');
            userlink=userlink.replace(".", "");
            userlink="https://ingeniumcanada.org/profile/"+userlink;
            return (
              <div key={job.nid} className="article">
                <a href={pageurl} target="_blank" rel="noopener noreferrer">
                  <div id={job.nid} style={{backgroundImage: `url(${imgurl})`}}><div className="card-angle-edge"></div></div>
                  <p className="articleTitle">{titletext}</p>
                  <p className="articleDesc">{bodytext}</p>
                  <p className="articleAuthor"><strong>Posted by: <a href={userlink} target="_blank" rel="noopener noreferrer">{job.uid}</a></strong></p>
                  <p className="articleDate">{job.created}</p>
                </a>
              </div>
            );
          })}
        </div>
      <p className="seeMore"><a href="https://ingeniumcanada.org/channel/boards/innovation-storybook" target="_blank" rel="noopener noreferrer">See more on the Channel</a></p>
      </div>
    )
  }
});

export default InnovationRandomArticles;