import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "./App";
import AppFR from "./AppFR";
/*import NotFound from "./NotFound";*/

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={App} />
      <Route exact path="/fr" component={AppFR} />
      <Route component={App} />
    </Switch>
  </BrowserRouter>
);

export default Router;
