import React from "react";
import InnovationRandomArticlesFR from "./InnovationRandomArticlesFR";
import TopFR from "./TopFR";
import BottomFR from "./BottomFR";
var createReactClass = require('create-react-class');

const App = createReactClass({
  componentDidMount(){
    document.title = "Récits d'innovation | Innovation Storybook"
  },
  render() {
    return (
      <div>
        <TopFR />
        <InnovationRandomArticlesFR />
        <BottomFR />
      </div>
    );
  }
})

export default App;
