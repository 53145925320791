import React from "react";
var createReactClass = require('create-react-class');

const Bottom = createReactClass({
 
  render: function() {
    return (
      <div id="bottom">
        <div className="bottomlogo">
          <a href="https://ingeniumcanada.org" target="_blank" rel="noopener noreferrer"><img alt="ingenium logo" src="/images/logo-ingenium.svg" /></a>
          <p>Canada’s Museums of Science and Innovation</p>
        </div>
        <hr className="gone" />
        <div className="contact">
          <h2>Connect With Us</h2>
          <div className="socialmedialogos">
            <a href="https://www.facebook.com/IngeniumCa" target="_blank" rel="noopener noreferrer"><img alt="facebook logo" src="/images/social-facebook.png" /></a> <a href="https://www.instagram.com/IngeniumCanada/" target="_blank" rel="noopener noreferrer"><img alt="instagram logo" src="/images/social-instagram.png" /></a> <a href="https://www.twitter.com/IngeniumCa" target="_blank" rel="noopener noreferrer"><img alt="twitter logo" src="/images/social-twitter.png" /></a>
          </div>
          <p>Email: <a href="mailto:innovation@ingeniumcanada.org">Innovation@IngeniumCanada.org</a></p>
        </div>
        <div className="clear"></div>
        <hr />
        &copy; {new Date().getFullYear()} Ingenium
        <p className="privacy"><a href="/fr">Français</a> | <a href="https://ingeniumcanada.org/privacy-statement" target="_blank" rel="noopener noreferrer">Privacy Statement</a></p>
      </div>
    )
  }
});

export default Bottom;