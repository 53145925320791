import React from "react";
var createReactClass = require('create-react-class');

const Top = createReactClass({
 
  render: function() {
    return (
      <div id="top">
        <img src="/images/IngeniumInnovationLogo.svg" alt="Ingenium Innovation logo"/>
        <p className="lang"><a href="/fr">Français</a></p>
        <div className="video-wrapper">
            <iframe title="Innovation Worth Celebrating" src="https://www.youtube.com/embed/W2DwoYBha-M?rel=0&mute=1&autoplay=1&showinfo=0" frameBorder="0" volume="0" allowFullScreen></iframe>
        </div>
        <div className="topText">
          <h1>About the Innovation Storybook</h1>
          <p> The Innovation Storybook was crowdsourced in 2017 as part of Innovation150 – an initiative of five leading science organisations, which celebrated innovation in Canada.</p>
          <p>The full Innovation Storybook has moved to <a href="https://ingeniumcanada.org/channel" target="_blank" rel="noopener noreferrer">Ingenium’s Channel</a>. You can view the articles in the <a href="https://ingeniumcanada.org/channel/boards/innovation-storybook" target="_blank" rel="noopener noreferrer">Innovation Storybook board</a>.</p>
          <p>The Channel is a digital hub featuring curated content related to science, technology and innovation. It helps science enthusiasts, teachers, parents, and curious Canadians share and engage with innovative stories through focused news, articles, blogs, podcasts, and videos.</p>
        </div>
      </div>
    )
  }
});

export default Top;