import React from "react";
import InnovationRandomArticles from "./InnovationRandomArticles";
import Top from "./Top";
import Bottom from "./Bottom";
var createReactClass = require('create-react-class');

const App = createReactClass({
  componentDidMount(){
    document.title = "Innovation Storybook | Récits d'innovation"
  },
  render() {
    return (
      <div>
        <Top />
        <InnovationRandomArticles />
        <Bottom />
      </div>
    );
  }
})

export default App;
